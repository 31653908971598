<template>
  <div class="rc-container">
    
    <div class="rc-row rc-font-bold" v-if="isEmployeeInactive(userData)">
      <div class="rc-box-col-15 rc-text-centre">This user has been removed</div>
    </div>
    
    <div class="rc-row rc-font-bold" v-if="isEmployeeInvited(userData)">
      <div class="rc-box-col-15 rc-text-centre">This user has been invited but has not signed in.</div>
    </div>
  
    <div class="rc-row rc-font-bold" v-if="isEmployeePending(userData)">
      <div class="rc-box-col-15 rc-text-centre">This user has not been invited.</div>
    </div>
    
    <Row>
    
      <Column :width="7">
        <LabelValue >
          <template #label>Name</template>
          <template #value>{{ user.first() }} {{ user.last() }}</template>
        </LabelValue>

        <LabelValue >
          <template #label>Contact</template>
          <template #value>{{ user.email() }} {{ user.phone() }}</template>
        </LabelValue>

        <LabelValue >
          <template #label>Employee Id</template>
          <template #value>{{ employmentId(userData) }}</template>
        </LabelValue>
        
        <LabelValue >
          <template #label>Role</template>
          <template #value>{{ role(userData) }}</template>
        </LabelValue>
      </Column>
      
      <Column :width="3">
        <Value>{{ address(userData) }}</Value>
        <Value>{{ city(userData) }} {{ province(userData) }}</Value>
        <Value>{{ country(userData) }} {{ postal(userData) }}</Value>
      </Column>
        
      <Column :width="3">
        <Value>{{ user.licenseClass() }}</Value>
        <Value>{{ user.licenseExpiryDateDisplay() }} </Value>
        <div 
          v-if="user.hasLicenseExpired()"
          style="background-color:#000000;color:#FFFFFF;padding: 1px 4px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
            License Expired!
        </div>
        <div 
          v-else-if="user.willLicenseExpireInWeeks(1)"
          style="background-color:#E90202;color:#FFFFFF;padding: 1px 4px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
            License expires in {{ user.licenseDaysLeft() }} days
        </div>
        <div 
          v-else-if="user.willLicenseExpireInWeeks(4)"
          style="background-color:#F39F42;color:#FFFFFF;padding: 1px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
          License expires in {{ user.licenseDaysLeft() }} days
        </div>
        <div 
          v-else-if="user.willLicenseExpireInWeeks(8)"
          style="background-color:#3F82B6;color:#FFFFFF;padding: 1px 4px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
            License expires in {{ user.licenseDaysLeft() }} days
        </div>
        <div 
          v-else-if="user.isLicenseClassMissing()"
          style="color:#000000;padding: 1px 4px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
            {{ MC.User.LicenseClassMissing.value() }}
        </div>
        <div 
          v-else-if="user.isLicenseExpiryMissing()"
          style="color:#000000;padding: 1px 4px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
            {{ MC.User.LicenseExpiryMissing.value() }}
        </div>
        <div 
          v-else
          style="background-color:#009436;color:#FFFFFF;padding: 1px 4px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
          License Good
        </div>
      </Column>
        
      <Column :width="2">
        <Value align="centre">{{ state(userData) }}</Value>
      </Column>
    </Row>
    
    <Row>
      <Column :width="7">
        <LabelValue >
          <template #label>Created By/On</template>
          <template #value>{{ user.createdBy().find().fullName() }} / {{ user.createdDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}</template>
        </LabelValue>
      </Column>
      <Column :width="8">
        <LabelValue>
          <template #label>Modified By/On</template>
          <template #value>{{ user.modifiedBy().find().fullName() }} / {{ user.modifiedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}</template>
        </LabelValue>
      </Column>
    </Row>
    
    <div v-if="isAdmin" class="rc-row">
      <div class="rc-box-col-2 rc-text-label">Company</div>
      <div class="rc-box-col-3 rc-text-value">
        <Value>{{ employee.company().find().name() }}</Value>
      </div>
      <div class="rc-box-col-1" v-if="!employee.isNew() && !employee.company().isNew()">
        <Button v-on:click="companyDetails(employee.company().id())">Details</Button>
      </div>
      <div class="rc-box-col-9" />
    </div>
    
    <StringValueRow v-if="isAdmin" label="Id" :value="employee.id()" />
    <StringValueRow v-if="isAdmin" label="Last Sign In" :value="user.lastSignInDisplay()" />
    <StringValueRow v-if="isAdmin" label="Device" :value="user.clientName()" />
    <StringValueRow v-if="isAdmin" label="Version" :value="user.clientVersion()" />
    <StringValueRow v-if="isAdmin" label="Make" :value="user.clientMake()" />
    <StringValueRow v-if="isAdmin" label="Employee Id" :value="employee.employmentId()" />
    <StringValueRow v-if="isAdmin" label="User Id" :value="user.id()" />
    <!--
    <StringImageRow v-if="isAdmin" label="Signature" :value="user.signature().find().image()" />
    -->
    <StringValueRow v-if="isAdmin" label="Signature Id" :value="user.signature().id()" />
    <StringValueRow v-if="isAdmin" label="Compliance Type" :value="employee.complianceType()" />
    
</div>
</template>

<script>
import { mapGetters } from "vuex";
import ContentUtils from "@/utils/ContentUtils.js";
import ConstUtils from "@/utils/ConstUtils.js";
import Utils from "@/views/portal/employees/Utils.js";
import MC from "@/domain/session/MC.js";

//import Company from "@/domain/model/company/Company.js";

import StringValueRow from '@/components/row/StringValueRow.vue';
//import StringImageRow from '@/components/row/StringImageRow.vue';
import Button from "@/portals/shared/library/button/Button.vue";

//import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import LabelValue from "@/portals/shared/library/labelvalue/LabelValue.vue";
import Value from "@/portals/shared/library/value/Value.vue";

import User from "@/domain/model/user/User.js";
import Employee from "@/domain/model/employee/Employee.js";

export default {
  name: "employee-details",
  components: {
    StringValueRow,
//    StringImageRow,
    Button,
    Row, Column,
    LabelValue, Value,
  },
  props: {
    userData: Object,
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      images: {},
      showConfirmRemove: false,
      removeEmployee: null,
      MC: new MC(),
    };
  },
  watch: {
    //watch
  },
  computed: {
    ...mapGetters([
      'auth_user', 
      'auth_client',
      'auth_connected',
      'companys_found',
      "roles_found",
      'domain',
    ]),
    user: function() {
      if (this.domain) {
        return new User(this.domain, this.userData);
      }
      return new User();
    },
    employee: function() {
      if (this.domain) {
        return new Employee(this.domain, this.userData['employee']);
      }
      return new Employee();
    }
  },
  methods: {
    decode: function (value) {
      return ContentUtils.unescape(value);
    },

    phoneNumber: function (user) {
      if (!user) {
        return "";
      }
      return Utils.phoneNumber(user);
    },

    address: function (user) {
      if (!user) {
        return "";
      }
      return Utils.address(user);
    },

    country: function (user) {
      if (!user) {
        return "";
      }
      return Utils.country(user);
    },

    postal: function (user) {
      if (!user) {
        return "";
      }
      return Utils.postal(user);
    },

    province: function (user) {
      if (!user) {
        return "";
      }
      return Utils.province(user);
    },

    city: function (user) {
      if (!user) {
        return "";
      }
      return Utils.city(user);
    },

    role: function (user) {
      if (!user) {
        return "";
      }
      return Utils.role(user);
    },

    state: function (user) {
      if (user && user.employee) {
        return user.employee.state;
      }
      return "Unknown";
    },
    employmentId: function (user) {
      if (user && user.employee) {
        return user.employee.employmentId;
      }
      return "";
    },
    imageError: function (user) {
      if (user) {
        const userId = user[ConstUtils.FIELDS.ID];
        this.images[userId] = ConstUtils.LABELS.IMAGE_NOT_FOUND;
      }
      if (event) {
        const userId = user[ConstUtils.FIELDS.ID];
        var imageUrl = this.images[userId];
        var url = this.auth_client.cdnHost + imageUrl;
        if (!event.target.src.includes(imageUrl)) {
          event.target.src = url;
        }
      }
    },

    isEmployeeInactive: function(user) {
      if (user && user.employee) {
        return user.employee.state == 'inactive';
      }
      return false;
    },
    isEmployeeInvited: function(user) {
      if (user && user.employee) {
        return user.employee.state == 'invited';
      }
      return false;
    },
    isEmployeePending: function(user) {
      if (user && user.employee) {
        return user.employee.state == 'notinvited';
      }
      return false;
    },
    displayImage: function (user) {
      if (!user) {
        return "";
      }
      var imageUrl = user.image;
      var brokenUrl = this.images[user[ConstUtils.FIELDS.ID]];
      if (brokenUrl) {
        imageUrl = brokenUrl;
      }
      var url = this.auth_client.cdnHost + imageUrl;
      return url;
    },
    companyDetails: function (companyId) {
      var params = {
          id: companyId, 
      };
      this.$router.push( {
            name: ConstUtils.ROUTES.COMPANY.ADMIN.DETAILS,
            params: params,
          });
    },

  },
};
</script>
